// @ts-ignore
import { getOptimisedImageUrl as _getOptimisedImageUrl } from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import { getOptimisedImageUrl as getOptimisedImageUrlType } from "@my-scoot/exly-react-component-lib/build/types/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import { IMAGE_OPTIMIZATION_CDN_URL } from "../constants/ExlyImage.constants";

const getOptimisedImageUrlBase: typeof getOptimisedImageUrlType =
    _getOptimisedImageUrl;

const getOptimisedImageUrl = ({
    imageUrl,
    fetchWidth,
    format,
    quality,
}: {
    imageUrl: string;
    fetchWidth: number;
    format?: string;
    quality?: number;
}) =>
    getOptimisedImageUrlBase({
        imageUrl,
        fetchWidth,
        cdnBaseUrl: IMAGE_OPTIMIZATION_CDN_URL,
        ...(format && {
            format,
        }),
        ...(quality && {
            quality,
        }),
    });

export { getOptimisedImageUrl };
