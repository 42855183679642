/* eslint-disable react/button-has-type */
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import styles from "./Button.module.scss";
import { IButton } from "./IButton";

/*
Notes
    - extraProps can have following keys
        style?: {[k: string]: string};
*/

export const Button = ({
    children,
    disabled,
    design,
    className,
    style,
    ...extraProps
}: IButton) => {
    const baseClassName = classNames(
        styles.button,
        disabled && styles.disabled,
        className
    );

    let designClassName = "";

    switch (design) {
        case "custom":
            break;
        case "pill":
            designClassName = styles.pillButton;
            break;
        case "plain":
            designClassName = styles.plainButton;
            break;
        case "rounded_corners":
        default:
            designClassName = styles.roundedCornersButton;
            break;
    }

    return (
        <button
            disabled={disabled}
            className={classNames(baseClassName, designClassName)}
            style={style}
            {...extraProps}
        >
            {children}
        </button>
    );
};

Button.defaultProps = {
    disabled: undefined,
    design: undefined,
};
